import React from 'react';

// styles 
import '../styles/landing.scss';

// images 
import Logo from '../logos/white-bg.svg';

// components 
import Navigation from '../components/Navigation.js'; 

function Landing() {
    return (
        <div id="land-container">
            <Navigation/>
            <div id="land-content">
                <img id="land-logo" src={Logo} alt="Elixer Tech logo"/>
                <h3>COMING SOON</h3>
            </div>
        </div>
    );
}

export default Landing;