import React from 'react';
import { Link } from 'react-router-dom';

// styles 
import '../styles/navigation.scss'; 

// logo 
import Logo from '../logos/white-logo.svg';

function Navigation() {
    return (
        <div id="nav-container">
            <Link to="/" id="nav-logo-link">
                <img src={Logo} id="nav-logo" alt="Elixer Tech logo in black."/>
            </Link>

            <div id="navbar">
                <p>Hello</p>
            </div>
        </div>
    );
}

export default Navigation;