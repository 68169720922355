import React from 'react';
import { Route } from 'react-router-dom';

// styles 
import './App.css';

// components 
import Landing from './components/Landing';
import Navigation from './components/Navigation';

function App() {
  return (
    <div className="App">
      <Navigation/>
      
      <div id="all-content">
        <Route exact path="/" component={Landing}/>
      </div>
    </div>
  );
}

export default App;
